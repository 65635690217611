<template>
  <div class="auth__form__content" id="auth-login">
    <r-card>
      <h2 class="is-size-2">Invite User</h2>
      <div
        v-for="(user, index) in users"
        :key="`email-${index}`"
        class="columns is-multiline"
      >
        <b-input
          maxlength="60"
          v-model="user.email"
          class="column is-6"
          placeholder="email"
          size="is-size-5"
        ></b-input>
        <b-select
          placeholder="Role"
          expanded
          class="column is-4"
          v-model="user.role"
          size="is-size-5"
        >
          <option value="staff" key="staff">Admin</option>
          <option value="member" key="member">Employee</option>
        </b-select>
        <b-icon
          icon="delete"
          class="column is-2"
          @click.native="deleteUser(index)"
        ></b-icon>
      </div>
      <b-button
        class="is-size-5"
        @click="addMoreUser()"
        :disabled="users.length >= 5"
      >
        Add more
      </b-button>

      <div class="columns">
        <div class="column is-offset-2 is-3">
          <b-button
            class="is-size-5"
            @click="sendInvite()"
            expanded
            :disabled="checkForm"
          >
            Send Invite
          </b-button>
        </div>

        <div class="column is-offset-2 is-3" v-if="users.length >= 5">
          Max 5 Invitation
        </div>
      </div>
    </r-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: [{ email: null, role: null }],
    }
  },
  computed: {
    checkForm() {
      let disabled = false
      this.users.forEach((element) => {
        if (
          element.email == null ||
          element.role == null ||
          element.email == '' ||
          element.role == ''
        ) {
          disabled = true
        }
      })
      return disabled
    },
  },
  methods: {
    addMoreUser() {
      this.users.push({ email: null, role: null })
    },
    deleteUser(index) {
      console.log(index)
      this.users.splice(index, 1)
    },
    sendInvite() {
      this.$buefy.dialog.confirm({
        title: 'Invite Users',
        message: `Are you sure you want to <b>invite</b> theses users? This action cannot be undone.`,
        confirmText: `Send Invite`,
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store
            .dispatch('user/inviteUsers', this.users)
            .then(() => {
              this.$buefy.toast.open({
                message: `These users has been invited`,
                type: 'is-success',
              })
              this.$parent.close()
            })
            .catch((error) => {
              // this.$emit('close')
              console.log(error)
              this.$parent.close()
            })
        },
      })
    },
  },
}
</script>
