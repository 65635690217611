<template>
  <div class="columns">
    <div class="column is-half is-offset-one-quarter">
      <r-card class="auth__form">
        <div class="auth__form__content" id="auth-login" v-if="!isLoading">
          <h2 class="has-text-centered is-size-2">
            Welcome to SPACE ID
            <br />
            Please add minimum 3 Users
          </h2>
          <div
            class="box"
            v-for="(companyUser, key) in company.companyUsers"
            :key="key"
          >
            <div class="field is-size-5">
              {{ companyUser.user.email }}
              <br />
              {{ companyUser.role }}
            </div>
          </div>
          <b-button @click="openModal()" expanded class="is-size-5">
            Add Another User
          </b-button>

          <router-link
            to="/select/product"
            v-if="
              company &&
              company.companyUsers &&
              company.companyUsers.length >= 0
            "
            class="is-link is-size-5"
          >
            Skip >
          </router-link>
          <!-- Modal Form Company -->
          <b-modal v-model="isFormUserModalActive" :can-cancel="true">
            <template #default="props">
              <FormUser @fetch="fetchUsersDataByCompany" @close="props.close" />
            </template>
          </b-modal>
        </div>
      </r-card>
    </div>
  </div>
</template>

<script>
import FormUser from '../../../layouts/form/user.vue'

export default {
  components: {
    FormUser,
  },
  data() {
    return {
      company: {},
      isFormUserModalActive: false,
      isLoading: false,
    }
  },
  async created() {
    await this.$store.dispatch('auth/getCurrentUser')
    let role = this.$store.getters['auth/currentUser'].user.role
    if (role == 'member') {
      this.$router.push('/select/product')
    } else {
      this.isLoading = true
      this.fetchUsersDataByCompany()
    }
  },
  methods: {
    openModal() {
      this.isFormUserModalActive = true
    },
    fetchUsersDataByCompany() {
      this.$store
        .dispatch('company/getCurrentCompany')
        .then((response) => {
          this.company = response.data.data
          this.isLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.isLoading = false
        })
    },
  },
}
</script>
